/** contact column /**/
.contact-info-link {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
}

a.contact-info-link {
    text-decoration: none;
    color: var(--color-text);
}

a.contact-info-link:hover {
    text-decoration: underline dotted;
}

.contact-info-link>svg {
    font-size: 1rem;
    margin-right: .35rem;
}

/** Contacts /**/
.contacts {
    width: 100%;
    border-radius: 4px;
}

.contacts button {
    border-radius: 0;
}

.contacts>div {
    display: flex;
}

.contacts>div>div,
.contacts div {
    border-radius: 0;
}

.contacts>div>div:not(:first-child) .MuiOutlinedInput-notchedOutline {
    margin-left: -1px;
}

.contacts>div:not(:first-child)>div .MuiOutlinedInput-notchedOutline {
    margin-top: -1px;
}

.contacts>div:first-child>div:first-child,
.contacts>div:first-child>div:first-child * {
    border-top-left-radius: 4px;
}

.contacts>div:last-child>div:first-child,
.contacts>div:last-child>div:first-child * {
    border-bottom-left-radius: 4px;
}

.contacts>div:first-child>button {
    border-top-right-radius: 4px;
}

.contacts>div:last-child>button {
    border-bottom-right-radius: 4px;
}

/** Groups /**/
.client-group {
    width: 100%;
    margin-top: 1px;
}

.client-group .client-group-actions>div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.client-group .client-group-actions>div>div:first-child {
    flex: 1;
}

/** Accounts /**
.person-accounttab-table thead {
    display: none;
}

.person-accounttab-table.datatable-compact,
.person-accounttab-table.datatable-compact table,
.person-accounttab-table.datatable-compact tbody {
    background-color: transparent;
}

.person-accounttab-table.datatable-compact tbody tr {
    display: block;
    margin-top: 1px;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.667);
}

.person-accounttab-table.datatable-compact tbody tr>td,
.person-accounttab-table.datatable-compact tbody tr>td:not(:first-child) {
    display: inline;
    border: none;
}

.person-accounttab-table.datatable-compact tbody tr>td:nth-child(1) {
    max-width: 75%;
}

.person-accounttab-table.datatable-compact tbody tr>td:nth-child(1)::after {
    content: ':';
}

.person-accounttab-table.datatable-compact tbody tr>td:nth-child(2) {
    margin-left: auto;
    width: 25%;
}

.person-accounttab-table.datatable-compact tbody tr>td:nth-child(4) {
    display: block;
}

.person-accounttab-table.datatable-compact tbody tr:not(:first-child)>td:not(:empty):nth-child(4)::before {
    content: 'Пополнить с помощью абонемента:';
    font-size: .9em;
    opacity: .8;
}

.person-accounttab-table.datatable-compact tbody tr>td:nth-child(5):empty{
    display: none;
}

.person-accounttab-table.datatable-compact tbody tr>td:nth-child(5)::before {
    content: 'Ближайшее списание:';
    font-size: .9em;
    opacity: .8;
}
/**/

.person-accounttab-table.datatable-compact,
.person-accounttab-table.datatable-compact table,
.person-accounttab-table.datatable-compact tbody {
    background-color: transparent;
}

.person-accounttab-table.datatable-compact thead th>button {
    display: none;
}

.person-accounttab-table.datatable-compact thead th input {
    position: relative;
}

.person-accounttab-table.datatable-compact thead th input::before {
    content : "some other placeholder very long" ;
    position: absolute;
    inset: 0 5px;
    z-index: 1;
    color: mintcream;
    background: thistle;
    width: calc(100% - 10px);
    white-space: nowrap;
    overflow: hidden;
    pointer-events: none;
  }

.person-accounttab-table.datatable-compact tbody tr {
    display: block;
    margin-top: 1px;
    background-color: rgba(255, 255, 255, 0.667) !important;
}

.person-accounttab-table.datatable-compact tbody tr>td:first-child,
.person-accounttab-table.datatable-compact tbody tr>td:not(:first-child):not(:nth-child(4)) {
    display: inline-flex;
    align-items: center;
    border: none;
}

.person-accounttab-table.datatable-compact tbody tr>td:nth-child(4) {
    width: 300%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.person-accounttab-table.datatable-compact tbody tr>td:not(:empty):nth-child(4)::before {
    content: 'Пополнить с помощью абонемента:';
    width: 100%;
    font-size: .9em;
    opacity: .8;
}

.person-accounttab-table.datatable-compact tbody tr {
    display: grid;
    grid-template-areas:
        "A B C"
        "D D D"
        "E E E";
    grid-template-columns: 33.33% 33.33% 33.33%;
}