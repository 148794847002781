@import "../index.css";

#login-wrapper {
    align-self: center;
    align-items: stretch;
    max-width: 360px;
    margin: auto;
}

#login-form {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #fff;
    padding: 30px 20px;
    border-top: 10px solid var(--color-secondary);
}

#login-form>svg {
    margin-left: auto;
    margin-right: auto;
    width: 35%;
}

#login-form>* {
    margin-top: 10px;
}