.loading-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.dataview-form .datatable-toolbar {
    margin-right: 1.5rem;
}

/**
.dataview-form-wrapper{
    position: relative;
    animation-duration: 1s;
    animation-name: slidein;
}
/**/

@keyframes slidein {
  from {
    position: absolute;
    margin-left: 100%;
  }

  to {
    margin-left: 0%;
  }
}

@media (max-width: 1400px) {
    .dataview-form .newbutton-newtext {
        display: none;
    }
}