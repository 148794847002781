@import 'react-dadata/dist/react-dadata.css';

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  background-color: #EBECEC;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-primary: #0075A2
    /**#1CA1C1/**/
  ;
  --color-secondary: #EEE0CB;
  --color-error: #DC3522;
  --color-success: #5CA595;
  --color-background: #EEE0CB;
}

::-webkit-scrollbar {
  width: .66rem;
  border-radius: .66rem;
  background-color: rgba(25, 118, 210, 0.08);
}

::-webkit-scrollbar-thumb {
  background-color: #0075A255;
  border-radius: 10px;
}

a button {
  text-decoration: none;
}

textarea {
  font-size: 1.3rem;
}

.react-dadata__input {
  border: initial;
  border-radius: initial;
  box-sizing: initial;
  display: initial;
  font-size: initial;
  height: initial;
  outline: initial;
  padding: initial;
  width: initial;
}

#root .leaflet-attribution-flag {
  display: none !important;
}

@media (max-width:1024px) {
  .MuiAppBar-root {
    box-shadow: none !important;
  }
}

.react-joyride__beacon>span:first-child {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.react-joyride__beacon>span:first-child::after {
  content: "?";
  color: #fff;
  font-weight: bold;
}

.react-joyride__tooltip a[data-route]{
  font-weight: 600;
}