.streams-wrapper {
    width: 360px;
    max-width: 50%;
    position: relative;
}

    .streams-wrapper > * {
        flex: 1;
        max-width: 100%;
    }

    .streams-wrapper.full-screen > * {
        display: none;
    }

    .streams-wrapper.full-screen > .full-screen-element {
        display: initial;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        margin: 0;
        z-index: 1000;
        opacity: 1;
    }

    .streams-wrapper .local-stream {
        opacity: .5;
        position: absolute;
        left: 5px;
        bottom: 5px;
        width: 150px;
    }

@media(max-width:640px) {
    .streams-wrapper {
        position: absolute;
        top: 40px;
        width: 100%;
        max-width: 100%;
    }

        .streams-wrapper > * {
            width: calc(50% - 10px);
            flex: 0;
            margin: 5px;
        }

        .streams-wrapper .local-stream {
            position: initial;
            width: calc(35% - 10px);
        }
}
