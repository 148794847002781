:root {
    --fc-button-bg-color: transparent !important;
    --fc-button-border-color: none !important;
    --fc-button-text-color: #0288d1 !important;
    --fc-button-hover-bg-color: rgba(0, 0, 0, 0.04) !important;
    --fc-button-hover-border-color: none !important;
    --fc-button-active-bg-color: #0288d144 !important;
    --fc-button-active-border-color: none !important;
}

.fc .fc-button-primary {
    box-shadow: none !important;
}

.fc-header-toolbar {
    margin: 1rem !important;
}

.fc-theme-standard .fc-scrollgrid{
    border-left: none!important;
}