.messanger {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;
    flex: 1;
}

.messanger>div {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.chat,
.chat-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.chat-toolbar {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--mdc-theme-box-shadow);
    align-items: center;
}

.chat-toolbar h4:first-child {
    margin-left: 1em;
    cursor: pointer;
}

.message-list {
    flex: 1;
    overflow-y: auto;
}

.message-list .message-row {
    margin: 7px;
    display: flex;
}

.message-list .message-row .message-status {
    font-size: .6em;
    padding-left: 1em;
    padding-top: 0.75em;
    float: right;
    display: flex;
}

.message-list .message-row .message-status svg {
    height: .6em;
    width: .6em;
}

.message-list .message-row .message-status svg+svg {
    margin-left: -.35em;
}

.message-list .message-row.message-self {
    flex-direction: row-reverse;
}

.message-list .message-row.message-self .message {
    border-bottom-left-radius: 0;
}

.message-list .message-row:not(.message-self):not(.message-system) .message {
    border-bottom-right-radius: 0;
    /** background-color: #fafafa;/**/
}

.message-list .message-row.message-system {
    justify-content: center;
}

.message-list .message-row.unreaded>div:last-child {
    border: 1px solid var(--mdc-theme-primary);
    border-radius: 50%;
    background-color: var(--mdc-theme-light);
    height: 5px;
    width: 5px;
    margin: auto 10px;
}

.message-list .message-row .status-wrapper {
    display: flex;
    flex-direction: row;
    max-width: calc(100% - 60px);
}

.message-list .message-row .status-wrapper>.rmwc-icon {
    color: aquamarine;
    font-size: 1em;
}

.message-list .message-row .status-wrapper>.rmwc-icon+.rmwc-icon {
    margin-left: -0.6em;
}

.message-list .message-row .message {
    margin: 0 7px;
    border-radius: 10px;
    overflow: hidden;
    padding: 2px 5px;
    box-shadow: 0 1px 2px 0 rgba(16, 35, 47, .15);
}

.message-list .message-row .message>div.img {
    width: 100%;
    display: grid;
}

.message-list .message-row .message>div.img>img {
    max-width: 200px;
    cursor: pointer;
}

.message-list .unreaded-separator {
    text-align: center;
    background-color: rgba(25, 118, 210, 0.08);
    padding: .25em 0;
}

video[name="LOCAL_VIDEO"] {
    opacity: .5;
}

.message-self {
    justify-content: end;
}

/**
.message-input {
    height: 5em;
    flex-direction: row;
    flex: 1;
}

.message-input {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: #fff;
        z-index:1;
    }
/**/

.mdc-text-field--textarea {
    flex-direction: row;
}

@media(max-width:640px) {
    .messanger > div {
        flex: 1;
    }
}